<template>
  <div class="card">
    <div class="container">
      <b-modal ref="editSearchIndexModal" hide-footer>
        <editSearchIndex
          :editMode="editMode"
          :editingSearchIndexs="currentSearchIndex"
          @closeEditSearchIndexs="toggleEditSearchIndex()"
          @editSearchIndexsSuccess="editSearchIndexSuccess()"
          @closeAddSearchIndexs="toggleAddSearchIndex()"
          @addSearchIndexsSuccess="addSearchIndexSuccess()"
        ></editSearchIndex>
      </b-modal>

      <b-modal ref="testSearch" hide-footer>
        <testSearch></testSearch>
      </b-modal>
      <div class="row">
        <div class="col">
          <br />
          <h2>Search Index</h2>
        </div>
      </div>
      <div class="row">
        <button
          @click="toggleAddSearchIndex()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      <button
          @click="showSearch()"
          type="button"
          class="btn btn-warning"
        >
          Test Search
        </button>
        
      </div>
      <div class="row">
        <br />

        <DataBlocks
          @itemSelected="itemSelected"
          :data="items"
          :fields="['Title', 'Description']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import editSearchIndex from "../../components/editSearchIndex";
import testSearch from "../../components/testSearch";


import DataBlocks from "@/components/custom/dataBlocks.vue";
import VueNotifications from "vue-notifications";
import { Datatable } from "../../mdb/components/Datatable";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      items: [],
      bannerTypes: [],
      addSearchIndexOpen: false,
      editSearchIndexs: false,
      currentSearchIndex: {},
      editMode: false,
    };
  },
  components: {
    editSearchIndex,
    testSearch,
    Datatable,
    DataBlocks,
  },
  created: function () {
    this.getSearchIndex();
    this.addSearchIndexOpen = false;
    this.editSearchIndexOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },

    getSearchIndex() {
      var payload = {
        success: (response) => {
          this.items = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getSearchIndexes", payload);
    },
    editItem(SearchIndex) {
      this.editMode = true;
      this.toggleEditSearchIndex();
      this.currentSearchIndex = SearchIndex;
    },
    itemSelected(item) {
      this.editMode = true;
      this.currentSearchIndex = item;
      if (this.editSearchIndexOpen) {
        this.$refs.editSearchIndexModal.hide();
      } else {
        this.$refs.editSearchIndexModal.show();
      }
      this.editSearchIndexOpen = !this.editSearchIndexOpen;
    },
    showSearch(){
       this.$refs.testSearch.show();
    },
    toggleAddSearchIndex() {
      this.editMode = false;
      this.currentSearchIndex = {};
      if (this.editSearchIndexOpen) {
        this.$refs.editSearchIndexModal.hide();
      } else {
        this.$refs.editSearchIndexModal.show();
      }
      this.editSearchIndexOpen = !this.editSearchIndexOpen;
    },
    addSearchIndexSuccess() {
      this.toggleAddSearchIndex();
      miniToastr["success"]("SearchIndex Added", "Success", 1000, null);
      this.getSearchIndex();
    },
    toggleEditSearchIndex() {
      this.editMode = true;
      if (this.editSearchIndexOpen) {
        this.$refs.editSearchIndexModal.hide();
      } else {
        this.$refs.editSearchIndexModal.show();
      }
      this.editSearchIndexOpen = !this.editSearchIndexOpen;
    },
    editSearchIndexSuccess() {
      this.toggleEditSearchIndex();
      miniToastr["success"]("SearchIndex Edited", "Success", 1000, null);
      this.getSearchIndex();
    },
  },
};
</script>
<style scoped>
.SearchIndexThumbnail {
  height: 50px;
}
</style>
