<template>
  <div class="container">
    <h2>
      Test Search Index(Results are only for testing, looks different on the
      website)
    </h2>

    <div class="input-group">
      <div class="form-outline">
        <input type="search" v-model="search" id="form1" class="form-control" />
      </div>
      <button type="button" class="btn btn-primary" @click="doSearch()">
        <i class="fas fa-search"></i>
      </button>
    </div>

    <div class="results">
      <div class="item" v-for="item of result" :key="item.Id">
        <div class="text">
          <div class="type" :class="item.Type">
            {{ item.Type == "page" ? item.Title : item.Type }}
          </div>
          <div class="title">
            {{ item.Title }}
          </div>
          <div class="description">
            {{ item.KeyWords }}
          </div>

          <div class="link">
            <a
              target="_blank"
              :href="'https://www.hyundai.co.za' + item.Link"
              >{{ item.Link }}</a
            >
          </div>
        </div>

        <div class="image">
          <img :src="getImageSource(item)" alt="" />
        </div>
      </div>
    </div>

    <pager
      @navigate="navigate($event)"
      :currentPage="this.currentPage"
      :totalPages="this.totalPages"
    ></pager>
  </div>
</template>
<script>
import Pager from "@/components/custom/Pager.vue";
export default {
  name: "TestSearch",
  props: {},
  data() {
    return {
      search: "",
      result: null,
      totalPages: null,
      pages: null,
      currentPage: 1,
    };
  },
  components: { Pager },
  created() {},

  props: [],

  methods: {
    navigate(page) {
      this.currentPage = page;
      this.doSearch();
    },
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    doSearch() {
      var payload = {
        data: { search: this.search, page: this.currentPage },
        success: (response) => {
          this.result = response.data.Result;
          this.pages = [];
          this.totalPages = response.data.TotalPages;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("doSearch", payload);
    },
  },
};
</script> 
<style lang="scss" scoped>
.container {
  background-color: #e5e5e5;
  .form-outline {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .results {
    padding-top: 20px;
    .item {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 20px;
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;
      background: white;
      .text {
        padding: 20px;
        .type {
          margin-bottom: 20px;
          background: #002c5f;
          border: 2.25px solid #002c5f;
          border-radius: 7px;
          color: white;
          font-family: "Arial";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          width: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          padding-left: 20px;
          padding-right: 20px;
          /* Hyundai White */

          color: #ffffff;
          &.News {
            background-color: #666666;
          }
          &.Event {
            background-color: #002c5f;
          }
          &.NewVehicle {
            background-color: #00aad2;
          }
          &.Promotion {
            background-color: #e63312;
          }
          &.page {
            background-color: #666666;
          }
        }
        .title {
          font-family: "HyundaiSansHeadBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
          /* identical to box height */

          /* Hyundai 1C1B1B */

          color: #1c1b1b;
        }
        .description {
          font-family: "HyundaiSansText";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          /* or 21px */

          /* Hyundai 1C1B1B */

          color: #1c1b1b;
        }
      }
      img {
        width: 300px;
      }
    }
  }
}
</style> 
