<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} search index</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deleteSearchIndexs()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="BannerType">Search Index</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="Title">Title</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingSearchIndexs.Title"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Copy">Description</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingSearchIndexs.Description"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="">Key Words</label>
        <textarea
          class="form-control"
          name="keywords"
          id=""
          cols="30"
          rows="10"
          v-model="editingSearchIndexs.KeyWords"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ImageUploader title="Image" v-model="editingSearchIndexs.Image" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class for="Link">Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingSearchIndexs.Link"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="LinkFriendly">External Link</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="ExternalLink"
          v-model="editingSearchIndexs.ExternalLink"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingSearchIndexs.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class for="LastUpdated">Type</label>
     
        <select   class="form-control browser-default"  style="display: block !important" v-model="editingSearchIndexs.Type">
          <option
            :value="item"
            :key="index"
            v-for="(item, index) in types"
          >
            {{ item }}
          </option>
        </select>
      
      </div>

      <div class="col-md-3">
        <label class for="">Priority</label>
        <input type="number" v-model="editingSearchIndexs.Priority" />
      </div>
    </div>

    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditSearchIndex",
  props: {
    options: {
      type: Array,
    },
  },
  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
      bannerTypes: null,
      types: [
        "category",
        "Dealer",
        "Event",
        "Newsroom",
        "NewVehicle",
        "page",
        "Promotion",
        "UsedVehicle",
      ],
    };
  },
  components: {
    ImageUploader,
  },
  created() {
    this.getBannerTypes();
    if (this.editMode) {
      this.originalData = JSON.parse(JSON.stringify(this.editingSearchIndexs));
      this.ImageBytes = this.editingSearchIndexs.Image;
    }
  },

  props: ["editingSearchIndexs", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditSearchIndexs");
    },
    getBannerTypes() {
      var payload = {
        data: this.editingSearchIndexs,
        success: (response) => {
          this.bannerTypes = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getSearchIndexTypes", payload);
    },
    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingSearchIndexs));
      data.UserAdded = true;
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editSearchIndexsSuccess");
          } else {
            this.$emit("addSearchIndexsSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.editMode) {
        this.$store.dispatch("editSearchIndex", payload);
      } else {
        this.$store.dispatch("addSearchIndex", payload);
      }
    },
    imageUploaded: function (file) {
      this.editingSearchIndexs.Image = file;
    },
    imageUploadedMobile: function (file) {
      this.editingSearchIndexs.MobileImage = file;
    },
    deleteSearchIndexs: function () {
      var payload = {
        data: this.editingSearchIndexs,
        success: (response) => {
          this.$emit("editSearchIndexsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteSearchIndex", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
