
    <template>
  <div class="pager-wrapper" v-if="totalPagesPrivate > 0">
    <div class="nav-button prev" @click="prev()">
      <svg
        width="13"
        height="21"
        viewBox="0 0 13 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2144 18.3361L5.15204 10.5001L12.2144 2.66624L10.038 0.256836L0.785451 10.5001L10.038 20.7433L12.2144 18.3361Z"
          :fill="prevActive ? '#002C5F' : '#efefef'"
        />
      </svg>
    </div>
    <div class="items">
      <div
        class="item"
        v-for="page of pages"
        :key="page"
        @click="itemClicked(page)"
        :class="{ current: page == currentPage }"
      >
        {{ page }}
      </div>
    </div>
    <div class="nav-button next" @click="next()">
      <svg
        width="13"
        height="21"
        viewBox="0 0 13 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.785644 2.66389L7.84796 10.4999L0.785644 18.3338L2.96201 20.7432L12.2145 10.4999L2.96201 0.256676L0.785644 2.66389Z"
          :fill="nextActive ? '#002C5F' : '#efefef'"
        />
      </svg>
    </div>
  </div>
</template>



<script>
export default {
  name: "Pager",
  props: ["currentPage", "totalPages"],
  data() {
    return {
      prevActive: false,
      nextActive: true,
      pages: null,
      totalPagesPrivate: 0,
      currentPagePrivate: 0,
    };
  },

  created() {
    this.totalPagesPrivate = this.totalPages;
  },
  mounted() {
    this.updatePages();
  },
  watch: {
    totalPages: function (newData) {
      

      this.totalPagesPrivate = newData;
      this.prevActive = this.currentPage > 1;
      this.nextActive = this.currentPage < this.totalPagesPrivate;
      this.updatePages();
    },
    currentPage: function (newData) {
      this.prevActive = this.currentPage > 1;
      this.nextActive = this.currentPage < this.totalPagesPrivate;
      this.currentPagePrivate = newData;
      this.updatePages();
    },
  },
  methods: {
    prev() {
      if (this.prevActive) {
        this.$emit("navigate", this.currentPagePrivate - 1);
      }
    },
    itemClicked(page) {
      this.$emit("navigate", page);
    },
    next() {
      if (this.nextActive) {
        this.$emit("navigate", this.currentPagePrivate + 1);
      }
    },
    updatePages() {
      this.pages = [];
      for (let index = 0; index < this.totalPagesPrivate; index++) {
        this.pages.push(index + 1);
      }
    },
  },
};
</script>


<style scoped lang="scss">
.pager-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .nav-button {
    height: 47px;
    width: 47px;
    background: #e4dcd3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .items {
    display: flex;
    flex-direction: row;
    .item {
      height: 47px;
      width: 47px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "HyundaiSansHeadBold";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #002c5f;
      &.current {
        background: #00aad2;
        color: white;
      }
    }
  }
}
</style>
